import React from 'react'
import { Countries } from 'comm'
import { useParams } from 'react-router-dom'
import { computeRead } from 'hooks/auth'
import useSWR, { mutate } from 'swr'

import useSWRMutation from 'swr/mutation'
import { putFetcher } from 'hooks'

import { Button } from '@/components/ui/button'

const SetReadable = () => {
  const { id } = useParams('')
  const { data: users } = useSWR(['/users'])
  const user = users?.find((user) => user.id === id)

  if (!user) return null

  return (
    <div className="flex flex-wrap gap-4">
      {Countries.map((e) => (
        <ToggleButton entity={e} key={e.code} user={user} />
      ))}
    </div>
  )
}

const ToggleButton = ({ user, entity }) => {
  const { code, name } = entity
  const reads = user?.camproiReadable?.split(',') ?? []
  const { trigger } = useSWRMutation(`/users/${user?.id}`, putFetcher)

  const handleClick = async () => {
    const value = computeRead(reads, code)
    trigger({ key: 'campaign', value }, { onSuccess: () => mutate(['/users']) })
  }

  const vari = reads.some((r) => r === code) ? 'default' : 'outline'

  return (
    <Button variant={vari} onClick={handleClick}>
      {name}
    </Button>
  )
}

export default SetReadable
