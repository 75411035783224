import React from 'react'
import {
  BrowserRouter,
  Link,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'

import RoiPCCost from 'features/roi/ProductContrast/Cost'
import RoiPCLayout from 'features/roi/ProductContrast/Layout'
import RoiPCProfit from 'features/roi/ProductContrast/Profit'
import RoiPCRevenue from 'features/roi/ProductContrast/Revenue'
import RoiHome from './features/roi/Home'
import RoiCountryDetail from './features/roi/Home/CountryContrast'
import RoiLayout from './features/roi/Layout'

import RoiWeekly from 'features/roi/Weekly'
import RoiWeeklyDoc from 'features/roi/WeeklyDoc'

import RoasDetail from 'features/Roas/Detail'
import RoasLayout from 'features/Roas/Layout'

import AppleEvents from 'features/apple/Events1'
import Event1Roadmap from 'features/apple/Events1Roadmap'
import AppleEvents2 from 'features/apple/Events2'
import Event2Roadmap from 'features/apple/Events2Roadmap'
import AppleEvents3 from 'features/apple/Events3'
import AppleLayout from 'features/apple/Layout'

import AdminLayout from 'features/admin/Layout'
import SetCostReadable from 'features/admin/SetCostReadable'
import SetPrReadable from 'features/admin/SetPrReadable'
import SetRevenueReadable from 'features/admin/SetRevenueReadable'
import SetRoasReadable from 'features/admin/SetRoasReadable'
import SetRoles from 'features/admin/SetRole'
import UserLayout from 'features/admin/UserLayout'

import EarningRate from 'features/er'

import Nav from './components/Nav'

import LoginPage from 'features/auth/FeishuLogin'

import { useAuthStore } from 'hooks'

const useWindowSize = () => {
  const [size, setSize] = React.useState([
    window.innerHeight,
    window.innerWidth,
  ])
  React.useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerHeight, window.innerWidth])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

const Welcome = () => {
  const size = useWindowSize()
  return (
    <div>
      <h1>Welcome to monday. </h1>
      <p>
        {size[1]} x{size[0]}
      </p>
    </div>
  )
}

const App = () => {
  return (
    <BrowserRouter>
      <Nav />
      <div className="container p-1">
        <Routes>
          <Route path="/" element={<RequireAuthLayout />}>
            <Route index element={<Welcome />} />
            <Route path="roi" element={<RoiLayout />}>
              <Route index element={<RoiHome />} />
              <Route
                path="cc/:pids/:date"
                element={<RoiCountryDetail />}
              ></Route>
              <Route path="pc" element={<RoiPCLayout />}>
                <Route index element={<RoiPCRevenue />} />
                <Route path="cost" element={<RoiPCCost />} />
                <Route path="profit" element={<RoiPCProfit />} />
              </Route>
              <Route path="weekly" element={<RoiWeekly />} />
              <Route path="weekdoc" element={<RoiWeeklyDoc />} />
            </Route>
            <Route path="apple" element={<AppleLayout />}>
              <Route path="" element={<AppleEvents />} />
              <Route path="events1" element={<AppleEvents />} />
              <Route
                path="events1/:eventAt/roadmap"
                element={<Event1Roadmap />}
              />
              <Route path="events2" element={<AppleEvents2 />} />
              <Route
                path="events2/:subAt/roadmap"
                element={<Event2Roadmap />}
              />
              <Route path="events3" element={<AppleEvents3 />} />
            </Route>
            <Route path="roas" element={<RoasLayout />}>
              <Route path=":id" element={<RoasDetail />} />
            </Route>
            <Route path="er" element={<EarningRate />} />
            <Route path="admin" element={<AdminLayout />}>
              <Route path="users/:id" element={<UserLayout />}>
                <Route path="roas" element={<SetRoasReadable />} />
                <Route path="revenue" element={<SetRevenueReadable />} />
                <Route path="cost" element={<SetCostReadable />} />
                <Route path="pr" element={<SetPrReadable />} />
                <Route path="roles" element={<SetRoles />} />
              </Route>
            </Route>
          </Route>
          <Route path="login" element={<LoginPage />} />
          <Route path="401" element={<UnAuth />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

const RequireAuthLayout = () => {
  const isLoggedIn = useAuthStore((state) => state.auth?.id)
  const location = useLocation()
  if (isLoggedIn) {
    return <Outlet />
  }
  return <Navigate to="/login" state={{ from: location }} replace />
}

function NoMatch() {
  return (
    <div>
      <h1>404</h1>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  )
}

function UnAuth() {
  return (
    <div>
      <h1>401</h1>
      <h2>Unauthorized!</h2>
    </div>
  )
}

export default App
