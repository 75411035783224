import React from 'react'
import { Button } from '@/components/ui/button'
import { computeRead } from 'hooks/auth'
import { useParams } from 'react-router-dom'
import useSWR, { mutate } from 'swr'
import { putFetcher } from 'hooks'
import useSWRMutation from 'swr/mutation'

export const Roles = [
  { code: 'admin', name: 'Admin' },
  { code: 'buyer', name: 'Buyer' },
  { code: 'revenuer', name: 'Revenuer' },
]

const SetReadable = () => {
  const { id } = useParams('')

  const { data: users } = useSWR(['/users'])
  const user = users?.find((user) => user.id === id)
  if (!user) return null

  return (
    <div className="flex flex-wrap gap-4">
      {Roles.map((p) => (
        <ToggleButton p={p} key={p.code} user={user} />
      ))}
    </div>
  )
}

const ToggleButton = ({ user, p }) => {
  const { code, name } = p
  const reads = user.roles?.split(',') ?? []
  const { trigger } = useSWRMutation(`/users/${user?.id}`, putFetcher)

  const handleClick = () => {
    const value = computeRead(reads, code)
    trigger({ key: 'role', value }, { onSuccess: () => mutate(['/users']) })
  }

  const vari = reads.some((r) => r === code) ? 'default' : 'outline'

  return (
    <Button variant={vari} onClick={handleClick}>
      {code} - {name}
    </Button>
  )
}

export default SetReadable
