import React from 'react'

const Td2 = ({ children, line2 }) => {
  return (
    <div className="flex flex-col text-right">
      <div className="text-nowrap">{children}</div>
      <div className="text-xs text-muted-foreground">{line2}</div>
    </div>
  )
}

export default Td2
