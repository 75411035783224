import React from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import SetComponent from './SetComponent'

const UPDATE_KEY = 'pr'
const READABLE_KEY = 'prReadable'

const SetReadable = () => {
  const { id } = useParams('')
  const { data: users } = useSWR(['/users'])
  const user = users?.find((user) => user.id === id)
  const reads = user?.[READABLE_KEY]?.split(',') ?? []

  return <SetComponent user={user} reads={reads} updateKey={UPDATE_KEY} />
}

export default SetReadable
