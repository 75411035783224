import React from 'react'
import { NavLink } from 'react-router-dom'
import DropdownCountry, { useCountry } from 'components/DropdownCountry'
import useSWR from 'swr'
import { cn } from '@/lib/utils'

const Item = ({ roas }) => {
  return (
    <NavLink
      to={roas.campaign_id}
      className={({ isActive }) =>
        cn(
          'inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:text-accent-foreground h-9 px-4 py-2 justify-start',
          isActive ? 'bg-muted' : 'hover:bg-transparent hover:underline',
        )
      }
    >
      {roas.campaign_name}
    </NavLink>
  )
}

const RoasList = () => {
  const country = useCountry((state) => state.value)
  const { data } = useSWR([`/camprois?country=${country}`])

  return (
    <div className="flex flex-col gap-4 py-4 sm:gap-2 sm:py-2">
      <div>
        <DropdownCountry />
      </div>
      <nav className="flex flex-col gap-2 py-2">
        {data?.map((roas) => {
          return <Item key={roas.campaign_name} roas={roas} />
        })}
      </nav>
    </div>
  )
}

export default RoasList
