import { formatNumber, formatPercent, formatUSD } from 'utils'
import { cn } from '@/lib/utils'

const Div = ({ className, children }) => {
  return <div className={cn('text-right', className)}>{children}</div>
}

export const countryColumn = {
  id: 'country',
  header: 'Country',
  accessorFn: (row) => row.country,
  cell: (row) => row.getValue(),
}

export const revenueColumn = {
  id: 'revenue-group',
  header: 'Revenue',
  columns: [
    {
      id: 'revenue-thisweek',
      header: 'This Week',
      accessorFn: (row) => row.thisWeekRevenues,
      cell: (row) => {
        const number = row.getValue() / 7
        return <Div>{formatUSD(number, 0, 0)}</Div>
      },
    },
    {
      id: 'revenue-lastweek',
      header: 'Last Week',
      accessorFn: (row) => row.lastWeekRevenues,
      cell: (row) => {
        const number = row.getValue() / 7
        return <Div>{formatUSD(number, 0, 0)}</Div>
      },
    },
    {
      id: 'revenue-diff',
      header: 'Diff',
      accessorFn: (row) => {
        const thisWeek = row.thisWeekRevenues
        const lastWeek = row.lastWeekRevenues
        return (thisWeek - lastWeek) / lastWeek
      },
      cell: (row) => {
        const number = row.getValue()
        return (
          <div
            className={cn(
              'text-right',
              number > 0.05 && 'bg-primary',
              number < -0.05 && 'bg-destructive',
            )}
          >
            {formatPercent(number)}
          </div>
        )
      },
    },
  ],
}

export const costColumn = {
  id: 'cost-group',
  header: 'Cost',
  columns: [
    {
      id: 'cost-thisweek',
      header: 'This Week',
      accessorFn: (row) => row.thisWeekCosts?.costs,
      cell: (row) => {
        const number = row.getValue() / 7
        return <Div>{formatUSD(number, 0, 0)}</Div>
      },
    },
    {
      id: 'cost-lastweek',
      header: 'Last Week',
      accessorFn: (row) => row.lastWeekCosts?.costs,
      cell: (row) => {
        const number = row.getValue() / 7
        return <Div>{formatUSD(number, 0, 0)}</Div>
      },
    },
    {
      id: 'cost-diff',
      header: 'Diff',
      accessorFn: (row) => {
        const thisWeek = row.thisWeekCosts?.costs
        const lastWeek = row.lastWeekCosts?.costs
        return (thisWeek - lastWeek) / lastWeek
      },
      cell: (row) => {
        const number = row.getValue()
        return (
          <div
            className={cn(
              'text-right',
              number > 0.05 && 'bg-primary',
              number < -0.05 && 'bg-destructive',
            )}
          >
            {formatPercent(number)}
          </div>
        )
      },
    },
  ],
}

export const profitColumn = {
  id: 'profit-group',
  header: 'Profit',
  columns: [
    {
      id: 'profit-thisweek',
      header: 'This Week',
      accessorFn: (row) => {
        const costs = row.thisWeekCosts?.costs
        const revenues = row.thisWeekRevenues
        return revenues - costs
      },
      cell: (row) => {
        const number = row.getValue() / 7
        return <Div>{formatUSD(number, 0, 0)}</Div>
      },
    },
    {
      id: 'profit-lastweek',
      header: 'Last Week',
      accessorFn: (row) => {
        const costs = row.lastWeekCosts?.costs
        const revenues = row.lastWeekRevenues
        return revenues - costs
      },
      cell: (row) => {
        const number = row.getValue() / 7
        return <Div>{formatUSD(number, 0, 0)}</Div>
      },
    },
    {
      id: 'profit-diff',
      header: 'Diff',
      accessorFn: (row) => {
        const thisWeekCosts = row.thisWeekCosts?.costs
        const thisWeekRevenues = row.thisWeekRevenues
        const lastWeekCosts = row.lastWeekCosts?.costs
        const lastWeekRevenues = row.lastWeekRevenues
        const thisWeekProfit = thisWeekRevenues - thisWeekCosts
        const lastWeekProfit = lastWeekRevenues - lastWeekCosts
        return (thisWeekProfit - lastWeekProfit) / lastWeekProfit
      },
      cell: (row) => {
        const number = row.getValue()
        return (
          <div
            className={cn(
              'text-right',
              number > 0.05 && 'bg-primary',
              number < -0.05 && 'bg-destructive',
            )}
          >
            {formatPercent(number)}
          </div>
        )
      },
    },
  ],
}

export const installColumn = {
  id: 'install-group',
  header: 'Install',
  columns: [
    {
      id: 'install-thisweek',
      header: 'This Week',
      accessorFn: (row) => row.thisWeekCosts?.installs,
      cell: (row) => {
        const number = row.getValue() / 7
        return <Div>{formatNumber(number, 0, 0)}</Div>
      },
    },
    {
      id: 'install-lastweek',
      header: 'Last Week',
      accessorFn: (row) => row.lastWeekCosts?.installs,
      cell: (row) => {
        const number = row.getValue() / 7
        return <Div>{formatNumber(number, 0, 0)}</Div>
      },
    },
    {
      id: 'install-diff',
      header: 'Diff',
      accessorFn: (row) => {
        const thisWeek = row.thisWeekCosts?.installs
        const lastWeek = row.lastWeekCosts?.installs
        return (thisWeek - lastWeek) / lastWeek
      },
      cell: (row) => {
        const number = row.getValue()
        return (
          <div
            className={cn(
              'text-right',
              number > 0.05 && 'bg-primary',
              number < -0.05 && 'bg-destructive',
            )}
          >
            {formatPercent(number)}
          </div>
        )
      },
    },
  ],
}

export const cpiColumn = {
  id: 'cpi-group',
  header: 'CPI',
  columns: [
    {
      id: 'cpi-thisweek',
      header: 'This Week',
      accessorFn: (row) => {
        const costs = row.thisWeekCosts?.costs
        const installs = row.thisWeekCosts?.installs
        return costs / installs
      },
      cell: (row) => {
        const number = row.getValue()
        return <Div>{formatUSD(number, 4, 4)}</Div>
      },
    },
    {
      id: 'cpi-lastweek',
      header: 'Last Week',
      accessorFn: (row) => {
        const costs = row.lastWeekCosts?.costs
        const installs = row.lastWeekCosts?.installs
        return costs / installs
      },
      cell: (row) => {
        const number = row.getValue()
        return <Div>{formatUSD(number, 4, 4)}</Div>
      },
    },
    {
      id: 'cpi-diff',
      header: 'Diff',
      accessorFn: (row) => {
        const thisWeekCosts = row.thisWeekCosts
        const lastWeekCosts = row.lastWeekCosts
        const thisWeekCpi = thisWeekCosts?.costs / thisWeekCosts?.installs
        const lastWeekCpi = lastWeekCosts?.costs / lastWeekCosts?.installs
        return (thisWeekCpi - lastWeekCpi) / lastWeekCpi
      },
      cell: (row) => {
        const number = row.getValue()
        return (
          <div
            className={cn(
              'text-right',
              number > 0.05 && 'bg-destructive',
              number < -0.05 && 'bg-primary',
            )}
          >
            {formatPercent(number)}
          </div>
        )
      },
    },
  ],
}
