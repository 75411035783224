import React from 'react'
import { Link } from 'react-router-dom'
import { cn } from '@/lib/utils'

const MyLink = ({ className, children, ...props }) => {
  return (
    <Link
      className={cn('font-medium text-primary/80 underline', className)}
      {...props}
    >
      {children}
    </Link>
  )
}

export default MyLink
