import { useRole, Role } from 'hooks/auth'

export const RoleBuyer = ({ strict, children }) => {
  const hasRole = useRole(Role.Buyer, strict)
  if (!hasRole) return null
  return children
}
export const RoleRevenuer = ({ strict, children }) => {
  const hasRole = useRole(Role.Revenuer, strict)
  if (!hasRole) return null
  return children
}

export const RoleAdmin = ({ children }) => {
  const hasRole = useRole(Role.Admin)
  if (!hasRole) return null
  return children
}
