import React, { useState, useMemo } from 'react'
import { formatNumber, formatPercent, formatUSD, getDateRange } from 'utils'
import dayjs from 'dayjs'
import { Products } from 'comm'
import { useDatePicker } from 'components/MyDatePicker'

import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import MyTable, {
  MyTableColumnVisible,
  MyTableFilter,
  createColumnVisibilityStore,
  useColumnFilters,
} from 'components/MyTable'
import Td2 from '../Home/Td2'
import useSWR from 'swr'
import { columnDate } from './columns'
import { Button } from '@/components/ui/button'
import { useCountry } from 'components/DropdownCountry'

const useColumnVisibilityStore = createColumnVisibilityStore({}, 'pcc')

const Home = () => {
  const startDate = useDatePicker((state) => state.startDate)
  const endDate = useDatePicker((state) => state.endDate)
  const country = useCountry((state) => state.value)

  const [rowSelection, setRowSelection] = useState({})
  const { columnVisibility, setColumnVisibility } = useColumnVisibilityStore()
  const { columnFilters } = useColumnFilters()

  const { data: costs, mutate } = useSWR([
    '/costs/pc',
    { startDate, endDate, country },
  ])

  const data = useMemo(() => {
    const dateRange = getDateRange(dayjs(startDate), dayjs(endDate))
    return dateRange.map((date) => ({
      date,
      datas: costs?.[date],
    }))
  }, [startDate, endDate, costs])

  const columns = useMemo(() => {
    const ps = Products.filter(({ code }) => !['22', '23'].includes(code))
    const rows = []
    rows.push(columnDate)

    ps.forEach((p) => {
      const { code, name } = p
      rows.push({
        id: `${code}`,
        header: `${code}-${name}`,
        columns: [
          {
            id: `${code}-costs`,
            header: `Costs`,
            accessorFn: (row) => {
              const curr = row.datas?.[code]
              const total = row.datas?.total
              return { curr, total }
            },
            cell: (row) => {
              const { curr, total } = row.getValue()
              const formatted = formatUSD(curr?.costs, 0, 0)
              const per = formatPercent(curr?.costs / total?.costs)
              return <Td2 line2={per}>{formatted}</Td2>
            },
          },
          {
            id: `${code}-installs`,
            header: 'Installs',
            accessorFn: (row) => {
              const curr = row.datas?.[code]
              const total = row.datas?.total
              return { curr, total }
            },
            cell: (row) => {
              const { curr, total } = row.getValue()
              const formatted = formatNumber(curr?.installs, 0, 0)
              const per = formatPercent(curr?.installs / total?.installs)
              return <Td2 line2={per}>{formatted}</Td2>
            },
          },
          {
            id: `${code}-cpi`,
            header: 'CPI',
            accessorFn: (row) => {
              const curr = row.datas?.[code]
              return curr?.costs / curr?.installs
            },
            cell: (row) => {
              const cpi = row.getValue()
              const formatted = formatUSD(cpi, 4, 4)
              return <Td2 line2="-">{formatted}</Td2>
            },
          },
        ],
      })
    })
    return rows
  }, [])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  const handleRowClick = (row) => {
    const newSelection = { ...rowSelection }
    if (newSelection[row.id]) {
      delete newSelection[row.id]
    } else {
      newSelection[row.id] = true
    }
    setRowSelection(newSelection)
  }

  return (
    <>
      {/* <pre>{JSON.stringify(columnFilters)}</pre> */}
      <div className="flex items-center gap-1 py-4">
        {/* <Button variant="outline">Hide Costs</Button>
        <Button variant="outline">Hide Installs</Button>
        <Button variant="outline">Hide CPI</Button> */}
        <MyTableFilter />
        <Button onClick={mutate}>refresh</Button>
        <MyTableColumnVisible table={table} />
      </div>
      <MyTable table={table} onRowClick={handleRowClick} />
    </>
  )
}

export default Home
