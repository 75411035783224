import React from 'react'
import { create } from 'zustand'
import dayjs from 'dayjs'
import { CalendarIcon } from '@radix-ui/react-icons'
import { format } from 'date-fns'

import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'

export const useDatePicker = create((set) => ({
  startDate: dayjs().subtract(30, 'day').format('YYYYMMDD'),
  endDate: dayjs().format('YYYYMMDD'),
  updateStartDate: (startDate) => set({ startDate }),
  updateEndDate: (endDate) => set({ endDate }),
}))

export const MyDatePicker = ({ className }) => {
  const startDate = useDatePicker((state) => state.startDate)
  const endDate = useDatePicker((state) => state.endDate)

  const from = dayjs(startDate).toDate()
  const to = dayjs(endDate).toDate()

  const updateStartDate = useDatePicker((state) => state.updateStartDate)
  const updateEndDate = useDatePicker((state) => state.updateEndDate)

  const handleSelectDate = (date) => {
    if (date) {
      updateStartDate(dayjs(date.from).format('YYYYMMDD'))
      updateEndDate(dayjs(date.to).format('YYYYMMDD'))
    }
  }

  const date = { from, to }
  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={'outline'}
            className={cn(
              'w-[300px] justify-start text-left font-normal',
              !date && 'text-muted-foreground',
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, 'LLL dd, y')} -{' '}
                  {format(date.to, 'LLL dd, y')}
                </>
              ) : (
                format(date.from, 'LLL dd, y')
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={handleSelectDate}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default MyDatePicker
