import {
  DropdownCountry1,
  createCountryStore,
} from 'components/DropdownCountry'
import MyDatePicker, { useDatePicker } from 'components/MyDatePicker'
import React, { useMemo, useState } from 'react'
import useSWR from 'swr'
import DeviceDropdown, { createDeviceStore } from './DropdownDevice'
import SkuDropdown, { createSkuIdStore } from './DropdownSku'

import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import MyTable, {
  MyTableColumnVisible,
  createColumnVisibilityStore,
  useColumnFilters,
} from 'components/MyTable'
import {
  billRetryColumn,
  cancelColumn,
  dnuColumn,
  eventAtColumn,
  freeTryColumn,
  gracePeriodColumn,
  paidColumn,
  reactivateColumn,
  refundColumn,
  renewColumn,
} from './columns'

const CACHE_KEY = 'event1'

const useColumnVisibilityStore = createColumnVisibilityStore({}, CACHE_KEY)
const useCountry = createCountryStore('US', CACHE_KEY)
const useSkuId = createSkuIdStore('1634387596', CACHE_KEY)
const useDevice = createDeviceStore('total', CACHE_KEY)

const columns = [
  eventAtColumn,
  dnuColumn,
  freeTryColumn,
  paidColumn,
  refundColumn,
  renewColumn,
  cancelColumn,
  billRetryColumn,
  reactivateColumn,
  gracePeriodColumn,
]

const Event1 = () => {
  const country = useCountry((state) => state.value)
  const updateCountry = useCountry((state) => state.update)
  const skuId = useSkuId((state) => state.value)
  const updateSkuId = useSkuId((state) => state.update)
  const { startDate, endDate } = useDatePicker((state) => ({
    startDate: state.startDate,
    endDate: state.endDate,
  }))

  const device = useDevice((state) => state.value)
  const updateDevice = useDevice((state) => state.update)
  const { columnVisibility, setColumnVisibility } = useColumnVisibilityStore()
  const { columnFilters } = useColumnFilters()
  const [rowSelection, setRowSelection] = useState({})

  const { data: events } = useSWR([
    '/apple/events',
    { pid: 12, country, startDate, endDate, skuId },
  ])
  const { data: dnus } = useSWR([
    '/apple/dnus',
    { pid: 12, country, startDate, endDate },
  ])
  const data = useMemo(() => {
    if (!events) return []
    const events1 = events.filter((e) => e[device] > 0)
    const uniqueAts = [...new Set(events1.map((item) => item.eventAt))]
    return uniqueAts.map((eventAt) => {
      const eventAtEvents = events1
        .filter((item) => item.eventAt === eventAt)
        .map(({ event, [device]: value }) => ({
          event,
          value,
        }))

      const freeTry =
        events1.find(
          (e) =>
            e.event === 'Start Introductory Offer' && e.eventAt === eventAt,
        )?.[device] || 0

      return {
        eventAt,
        events: eventAtEvents,
        freeTry,
        dnu: dnus?.find((item) => item.date === eventAt)?.[device],
      }
    })
  }, [events, dnus, device])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  const handleRowClick = (row) => {
    const newSelection = { ...rowSelection }
    if (newSelection[row.id]) {
      delete newSelection[row.id]
    } else {
      newSelection[row.id] = true
    }
    setRowSelection(newSelection)
  }

  return (
    <>
      <div className="flex items-center gap-4 py-4 sm:gap-2 sm:py-2 flex-wrap">
        <DropdownCountry1 value={country} update={updateCountry} />
        <MyDatePicker />
        <SkuDropdown value={skuId} update={updateSkuId} />
        <DeviceDropdown value={device} update={updateDevice} />
        <MyTableColumnVisible table={table} />
      </div>
      <MyTable table={table} onRowClick={handleRowClick} />
    </>
  )
}

export default Event1
