export const PTotals = [
  { code: 'total', name: 'total - All' },
  { code: 'total1', name: 'total1 - All Sticker' },
  { code: 'total2', name: 'total2 - All Neku' },
  { code: 'total3', name: 'total3 - All Other' },
]

export const PStickers = [
  { code: '1', name: 'Text' },
  { code: '2', name: 'Personal' },
  { code: '3', name: 'HD' },
  { code: '6', name: '2021' },
  { code: '4', name: 'Anim' },
  { code: '7', name: 'TG' },
  { code: '5', name: 'Maker' },
  { code: '9', name: 'StickTok' },
  { code: '13', name: 'HD2023' },
  { code: '14', name: 'JPSingle' },
  { code: '15', name: 'Keyboard' },
]

export const PNekus = [
  { code: '11', name: 'Neku-A' },
  { code: '12', name: 'Neku-iOS' },
  { code: '16', name: 'Neku(OC-A)' },
]
export const PGames = [
  { code: '22', name: 'jigsaw' },
  { code: '23', name: 'jigsaw-iOS' },
]

export const Products1 = [...PTotals, ...PStickers, ...PNekus, ...PGames]

export const Products = [
  { code: 'total', name: 'Total' },
  ...PStickers,
  ...PNekus,
  ...PGames,
]

export const Countries = [
  { code: 'total', name: 'Total' },
  { code: 'BR', name: 'BR *' },
  { code: 'JP', name: 'JP *' },
  { code: 'DE', name: 'DE' },
  { code: 'US', name: 'US *' },
  { code: 'MX', name: 'MX *' },
  { code: 'TW', name: 'TW' },
  { code: 'IT', name: 'IT' },
  { code: 'KR', name: 'KR' },
  { code: 'ES', name: 'ES *' },
  { code: 'HK', name: 'HK' },
  { code: 'SA', name: 'SA' },
  { code: 'TH', name: 'TH' },
  { code: 'CA', name: 'CA' },
  { code: 'lt5', name: 'LT5' },
  { code: 'FR', name: 'FR' },
  { code: 'CH', name: 'CH' },
  { code: 'SG', name: 'SG' },
  { code: 'IL', name: 'IL' },
  { code: 'ZA', name: 'ZA' },
  { code: 'ID', name: 'ID *' },
  { code: 'AT', name: 'AT' },
  { code: 'AR', name: 'AR *' },
  { code: 'GB', name: 'GB' },
  { code: 'CO', name: 'CO *' },
  { code: 'NL', name: 'NL' },
  { code: 'MY', name: 'MY *' },
  { code: 'AE', name: 'AE' },
  { code: 'BE', name: 'BE' },

  { code: 'AU', name: 'AU' },
  { code: 'PT', name: 'PT' },
  { code: 'NG', name: 'NG' },
  { code: 'IN', name: 'IN' },
  { code: 'EG', name: 'EG' },
  { code: 'TR', name: 'TR' },
  { code: 'CL', name: 'CL' },
  { code: 'RO', name: 'RO' },
  { code: 'OM', name: 'OM' },
  { code: 'FI', name: 'FI' },
  { code: 'NO', name: 'NO' },
  { code: 'PL', name: 'PL' },
  { code: 'UY', name: 'UY' },
  { code: 'IE', name: 'IE' },
  { code: 'PK', name: 'PK' },
  { code: 'LU', name: 'LU' },
  { code: 'KE', name: 'KE' },
  { code: 'RU', name: 'RU' },
]
