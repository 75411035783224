import React from 'react'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

export const Selector = ({ datas, labelName, value, update, showCode }) => {
  return (
    <Select onValueChange={update} defaultValue={value} value={value}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder={`Select a ${labelName}`} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>{labelName}</SelectLabel>
          {datas.map((item) => (
            <SelectItem key={item.code} value={item.code}>
              {showCode && `${item.code} - `}
              {item.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}

export default Selector
