import React, { useState, useMemo } from 'react'

import useSWR from 'swr'
import DropdownPid, { usePid } from 'components/DropdownPid'

import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import MyTable, {
  MyTableColumnVisible,
  createColumnVisibilityStore,
  useColumnFilters,
} from 'components/MyTable'
import {
  costColumn,
  countryColumn,
  cpiColumn,
  installColumn,
  profitColumn,
  revenueColumn,
} from './columns'

const useColumnVisibilityStore = createColumnVisibilityStore({}, 'weeklydoc')

const WeeklyDoc = () => {
  const pid = usePid((state) => state.value)
  const { columnVisibility, setColumnVisibility } = useColumnVisibilityStore()
  const { columnFilters } = useColumnFilters()
  const [rowSelection, setRowSelection] = useState({})

  const { data: revenue } = useSWR(['/revenues/reports', { pid, ws: 5 }])

  const { data: cost } = useSWR(['/costs/reports', { pid, ws: 5 }])

  const sortCountries = useMemo(() => {
    const c1 = Object.keys(revenue?.thisWeek || {})
    const c2 = Object.keys(cost?.thisWeek || {})
    const c3 = Array.from(new Set([...c1, ...c2]))

    const countries = c3.filter((id) => id !== 'weekId' && id !== 'weekDays')
    let sortCountries
    if (revenue) {
      sortCountries = countries.sort(
        (a, b) => revenue?.thisWeek?.[b] - revenue?.lastWeek?.[a],
      )
    } else if (cost) {
      sortCountries = countries.sort(
        (a, b) => cost?.thisWeek?.[b]?.costs - cost?.lastWeek?.[a]?.costs,
      )
    } else {
      sortCountries = countries
    }
    return sortCountries
  }, [revenue, cost])

  const data = useMemo(() => {
    return sortCountries?.map((country) => ({
      country,
      thisWeekRevenues: revenue?.thisWeek?.[country],
      lastWeekRevenues: revenue?.lastWeek?.[country],
      thisWeekCosts: cost?.thisWeek?.[country],
      lastWeekCosts: cost?.lastWeek?.[country],
    }))
  }, [sortCountries, revenue, cost])

  const columns = useMemo(() => {
    const rows = [countryColumn]
    rows.push(revenueColumn)
    rows.push(costColumn)
    rows.push(profitColumn)
    rows.push(installColumn)
    rows.push(cpiColumn)

    return rows
  }, [])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: { columnFilters, columnVisibility, rowSelection },
  })

  const handleRowClick = (row) => {
    const newSelection = { ...rowSelection }
    if (newSelection[row.id]) {
      delete newSelection[row.id]
    } else {
      newSelection[row.id] = true
    }
    setRowSelection(newSelection)
  }

  const thisWeek = (revenue ?? cost)?.thisWeek
  const lastWeek = (revenue ?? cost)?.lastWeek

  return (
    <>
      <div className="flex items-center gap-4 py-4 flex-wrap sm:gap-2 sm:py-2">
        <DropdownPid showLabel={false} />
        <div>
          this week:
          <span className="text-primary">
            {thisWeek?.weekDays?.[0]}~
            {thisWeek?.weekDays?.[thisWeek?.weekDays?.length - 1]}
          </span>
        </div>
        <div>
          last week:
          <span className="text-primary">
            {lastWeek?.weekDays?.[0]}~
            {lastWeek?.weekDays?.[lastWeek?.weekDays?.length - 1]}
          </span>
        </div>
        <div> daily avg, not week sum.</div>
        <MyTableColumnVisible table={table} />
      </div>
      <MyTable table={table} onRowClick={handleRowClick} />
    </>
  )
}

export default WeeklyDoc
