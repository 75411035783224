import React from 'react'
import RoasList from './List'
import { Outlet } from 'react-router-dom'

function Index() {
  return (
    <main className="flex flex-col">
      <div className="flex gap-4 sm:gap-2 py-4 sm:py-2">
        <h1 className="text-3xl font-semibold">
          Roas = Revenue/Cost from ad campaign
        </h1>
      </div>
      <div className="flex sm:flex-col md:flex-row gap-4 py-4 sm:gap-2 sm:py-2">
        <div className="lg:w-1/5">
          <RoasList />
        </div>
        <div className="flex-1">
          <Outlet />
        </div>
      </div>
    </main>
  )
}

export default Index
