import React from 'react'
import { useParams, Link } from 'react-router-dom'
import useSWR from 'swr'

import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card'
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar'
import { cn } from '@/lib/utils'

const UserList = () => {
  const { data: users } = useSWR([`/users`])
  return (
    <Card>
      <CardHeader>
        <CardTitle>User list</CardTitle>
      </CardHeader>
      <CardContent className="grid gap-4 sm:gap-2">
        {users?.map((user) => (
          <UserItem key={user.id} user={user} />
        ))}
      </CardContent>
    </Card>
  )
}

const UserItem = ({ user }) => {
  const { id } = useParams('')

  return (
    <Link to={`users/${user.id}`}>
      <div
        className={cn(
          'flex items-center gap-4 p-4 hover:bg-muted hover:rounded-md',
          user.id === id && 'bg-muted rounded-md text-primary',
        )}
      >
        <Avatar className="hidden h-9 w-9 sm:flex">
          <AvatarImage src={user.avatarThumb} alt="Avatar" />
          <AvatarFallback>OM</AvatarFallback>
        </Avatar>
        <div className="grid gap-1">
          <p className="text-sm font-medium leading-none">{user.name}</p>
          <p className="text-sm text-muted-foreground">{user.id}</p>
        </div>
      </div>
    </Link>
  )
}

export default UserList
