import Selector from 'components/ui/Selector'
import React from 'react'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
export const createDeviceStore = (init = 'total', key = '') =>
  create(
    persist((set) => ({ value: init, update: (value) => set({ value }) }), {
      name: `device-storage-${key}`,
      storage: createJSONStorage(() => localStorage),
    }),
  )

export const useDevice = create((set) => ({
  value: 'total',
  update: (value) => set({ value }),
}))

const DeviceDropdown = ({ value, update }) => {
  return (
    <Selector
      datas={[
        { code: 'total', name: 'Total' },
        { code: 'iPhone', name: 'iPhone' },
        { code: 'iPad', name: 'iPad' },
      ]}
      update={update}
      value={value}
      labelName={'Device'}
      showLabel
    />
  )
}
export default DeviceDropdown
