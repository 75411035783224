import {
  DropdownCountry1,
  createCountryStore,
} from 'components/DropdownCountry'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import DeviceDropdown, { createDeviceStore } from './DropdownDevice'
import SkuDropdown, { createSkuIdStore } from './DropdownSku'

import dayjs from 'dayjs'

import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import MyTable, {
  MyTableColumnVisible,
  createColumnVisibilityStore,
  useColumnFilters,
} from 'components/MyTable'

import { Button } from '@/components/ui/button'
import {
  billRetryColumn,
  cancelColumn,
  freeTryColumn,
  gracePeriodColumn,
  paidColumn,
  reactivateColumn,
  refundColumn,
  renewColumn,
} from './columns'

const subAtColumn = {
  id: 'subAt',
  header: 'Sub At',
  accessorFn: (row) => {
    const subAt = row.subAt
    const eventAt = row.eventAt
    const eventDay = dayjs(eventAt.toString(), 'YYYYMMDD')
    const subDay = dayjs(subAt.toString(), 'YYYYMMDD')
    const diffDays = eventDay.diff(subDay, 'day')
    return { subAt, eventAt, diffDays }
  },
  cell: (row) => {
    const { subAt, diffDays } = row.getValue()
    return (
      <div>
        {subAt} ({diffDays}day)
      </div>
    )
  },
}

const CACHE_KEY = 'event1roadmap'

const useColumnVisibilityStore = createColumnVisibilityStore({}, CACHE_KEY)
const useSkuId = createSkuIdStore('1634387596', CACHE_KEY)
const useDevice = createDeviceStore('total', CACHE_KEY)
const useCountry = createCountryStore('US', CACHE_KEY)

const EventRoadmap = () => {
  const { eventAt } = useParams()
  const skuId = useSkuId((state) => state.value)
  const updateSkuId = useSkuId((state) => state.update)
  const country = useCountry((state) => state.value)
  const updateCountry = useCountry((state) => state.update)
  const {
    data: events,
    mutate,
    isValidating,
  } = useSWR([`/apple/events1/${eventAt}/roadmap`, { pid: 12, country, skuId }])
  const device = useDevice((state) => state.value)
  const updateDevice = useDevice((state) => state.update)

  const { columnVisibility, setColumnVisibility } = useColumnVisibilityStore()
  const { columnFilters } = useColumnFilters()
  const [rowSelection, setRowSelection] = useState({})

  const data = useMemo(() => {
    if (!events) return []

    const events1 = events.filter((e) => e[device] > 0)
    const uniqueAts = [...new Set(events1.map((item) => item.subAt))]
    const freeTry =
      events1.find((e) => e.event === 'Start Introductory Offer')?.[device] || 0

    return uniqueAts.map((subAt) => {
      const subAtEvents = events1
        .filter((item) => item.subAt === subAt)
        .map(({ event, [device]: value }) => ({
          event,
          value,
        }))

      return {
        eventAt,
        subAt,
        events: subAtEvents,
        freeTry,
      }
    })
  }, [eventAt, events, device])

  const columns = useMemo(() => {
    const rows = []
    rows.push(subAtColumn)
    rows.push(freeTryColumn)
    rows.push(paidColumn)
    rows.push(refundColumn)
    rows.push(renewColumn)
    rows.push(cancelColumn)
    rows.push(billRetryColumn)
    rows.push(reactivateColumn)
    rows.push(gracePeriodColumn)
    return rows
  }, [])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  const handleRowClick = (row) => {
    const newSelection = { ...rowSelection }
    if (newSelection[row.id]) {
      delete newSelection[row.id]
    } else {
      newSelection[row.id] = true
    }
    setRowSelection(newSelection)
  }

  return (
    <>
      <div className="flex items-center gap-4 py-4 flex-wrap sm:gap-2 sm:py-2">
        <DropdownCountry1 value={country} update={updateCountry} />
        <SkuDropdown value={skuId} update={updateSkuId} />
        <DeviceDropdown value={device} update={updateDevice} />
        <Button onClick={() => mutate()}>
          refresh{isValidating ? '...' : ''}
        </Button>
        <MyTableColumnVisible table={table} />
      </div>
      <div> The event is at {eventAt}. What happened on this day?</div>
      <MyTable table={table} onRowClick={handleRowClick} />
    </>
  )
}

export default EventRoadmap
