import React, { useMemo } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { flexRender } from '@tanstack/react-table'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { ChevronDownIcon } from '@radix-ui/react-icons'
import Selector from 'components/ui/Selector'
import { cn } from '@/lib/utils'
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

const MyTable = ({ table, onHeadClick, onRowClick, onCellClick }) => {
  const visibleLength = useMemo(
    () =>
      table?.getAllLeafColumns().filter((column) => column.getIsVisible())
        ?.length,
    [table],
  )

  return (
    <Table className="border-collapse border border-slate-400">
      <TableHeader className="border-b-black border-b-2">
        {table?.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead
                  key={header.id}
                  colSpan={header.colSpan}
                  onClick={() => onHeadClick && onHeadClick(header)}
                  className="border border-muted text-bold bg-muted"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              )
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table?.getRowModel()?.rows?.length ? (
          table.getRowModel().rows.map((row, i) => (
            <TableRow
              key={row.id}
              className={cn(
                `data-[state=selected]:bg-yellow-100 hover:bg-yellow-100`,
                i % 2 === 0 ? 'bg-gray-200' : 'bg-white',
              )}
              data-state={row.getIsSelected() && 'selected'}
              onClick={() => onRowClick && onRowClick(row)}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  className="border border-slate-300 py-1"
                  onClick={() => onCellClick && onCellClick(cell)}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={visibleLength} className="h-24 text-center ">
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

export const MyTableColumnVisible = ({ table }) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <Button variant="outline" className="lg:ml-auto">
        Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end" className="max-h-80 overflow-y-auto">
      {table
        ?.getAllLeafColumns()
        .filter((column) => column.getCanHide())
        .map((column) => {
          return (
            <DropdownMenuCheckboxItem
              key={column.id}
              className="capitalize"
              checked={column.getIsVisible()}
              onCheckedChange={(value) => column.toggleVisibility(!!value)}
            >
              {column.id}
            </DropdownMenuCheckboxItem>
          )
        })}
    </DropdownMenuContent>
  </DropdownMenu>
)

export const useColumnFilters = create((set) => ({
  columnFilters: [],
  update: (value) =>
    set({
      columnFilters: value === -1 ? [] : [{ id: 'date', value: value }],
    }),
}))

const WSS = [
  { code: -1, name: 'Select VS Week' },
  { code: 1, name: 'VS 周一' },
  { code: 2, name: 'VS 周二' },
  { code: 3, name: 'VS 周三' },
  { code: 4, name: 'VS 周四' },
  { code: 5, name: 'VS 周五' },
  { code: 6, name: 'VS 周六' },
  { code: 0, name: 'VS 周日' },
]

export const MyTableFilter = () => {
  const value = useColumnFilters((state) => state.columnFilters?.[0]?.value)
  const update = useColumnFilters((state) => state.update)
  return (
    <Selector
      datas={WSS}
      showLabel
      labelName="VS Week"
      value={value}
      update={update}
    />
  )
}

export const createColumnVisibilityStore = (init = {}, key = '') =>
  create(
    persist(
      (set) => ({
        columnVisibility: init, // 初始化列可见性状态
        setColumnVisibility: (updateFn) =>
          set((state) => ({
            columnVisibility: updateFn(state.columnVisibility),
          })),
      }),
      {
        name: `column-visibility-storage-${key}`, // 存储的名称，包含唯一键
        storage: createJSONStorage(() => localStorage), // 使用localStorage来持久化
      },
    ),
  )

export default MyTable
