import { cn } from '@/lib/utils'

const Day = ({ value, high = 60, low = 45 }) => {
  if (!value) {
    return <div></div>
  }
  return (
    <div
      className={cn(
        'text-right',
        value >= high && 'bg-primary/60',
        value <= low && 'bg-destructive/60',
      )}
    >
      {value}%
    </div>
  )
}

export const dateColumn = {
  id: 'date',
  header: 'Date',
  accessorFn: (row) => row.date,
  cell: (row) => row.getValue(),
}

export const d1Column = {
  id: 'd1',
  header: 'D1',
  accessorFn: (row) => row.d1Roiod,
  cell: (row) => <Day value={row.getValue()} />,
}

export const d2Column = {
  id: 'd2',
  header: 'D2',
  accessorFn: (row) => row.d2Roiod,
  cell: (row) => <Day value={row.getValue()} low={50} high={65} />,
}

export const d3Column = {
  id: 'd3',
  header: 'D3',
  accessorFn: (row) => row.d3Roiod,
  cell: (row) => <Day value={row.getValue()} low={55} high={70} />,
}

export const d4Column = {
  id: 'd4',
  header: 'D4',
  accessorFn: (row) => row.d4Roiod,
  cell: (row) => <Day value={row.getValue()} low={60} high={75} />,
}

export const d5Column = {
  id: 'd5',
  header: 'D5',
  accessorFn: (row) => row.d5Roiod,
  cell: (row) => <Day value={row.getValue()} low={65} high={80} />,
}

export const d6Column = {
  id: 'd6',
  header: 'D6',
  accessorFn: (row) => row.d6Roiod,
  cell: (row) => <Day value={row.getValue()} low={70} high={85} />,
}

export const d7Column = {
  id: 'd7',
  header: 'D7',
  accessorFn: (row) => row.d7Roiod,
  cell: (row) => <Day value={row.getValue()} low={80} high={95} />,
}
