import { cn } from '@/lib/utils'
import { formatNumber } from 'utils'

export const countryColumn = {
  id: 'country',
  header: '#',
  accessorFn: (row) => row.country,
  cell: (row) => {
    const country = row.getValue()
    return <div>{country?.code}</div>
  },
}

export const pidColumn = (p) => ({
  id: `pid-er-${p.code}`,
  header: `${p.name}`,
  accessorFn: (row) => {
    const er = row.baselines?.[p.code]
    const vs = row.vs?.[p.code]
    return { pid: p.code, er, vs }
  },
  cell: (row) => {
    const { er, vs } = row.getValue()
    const vsFormatted = formatNumber(vs / 100, 2, 2)
    if (er === null || er === undefined) {
      return null
    }
    const differencePercentage = Math.abs(er - vs / 100) / (vs / 100)

    return (
      <div
        className={cn(
          'text-right',
          differencePercentage <= 0.1
            ? 'text-gray-400'
            : er > vs / 100
            ? 'text-destructive'
            : 'text-primary',
          differencePercentage > 0.1 ? 'font-bold' : '',
        )}
      >
        {er}/{vsFormatted}
      </div>
    )
  },
})
