import { useMemo } from 'react'
import { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'
import { putFetcher } from 'hooks'
import { isReadable, computeRead } from 'hooks/auth'

import { Button } from '@/components/ui/button'

import { Countries, Products } from 'comm'
import { Check, X } from 'lucide-react'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import MyTable from 'components/MyTable'
import { cn } from '@/lib/utils'

const SetComponent = ({ user, reads, updateKey }) => {
  const { trigger } = useSWRMutation(`/users/${user?.id}`, putFetcher)
  const onUpdateReadable = (pr) => {
    const value = computeRead(reads, pr)
    trigger({ key: updateKey, value }, { onSuccess: () => mutate(['/users']) })
  }

  const data = useMemo(() => {
    return Countries.map((c) => {
      const country = c.code
      const meta = { country }
      Products.forEach((p) => {
        meta[p.code] = isReadable(reads, p.code, country)
      })
      return meta
    })
  }, [reads])

  const columns = [
    {
      id: 'country',
      header: <div>&#92;</div>,
      accessorFn: (row) => {
        const pid = '*'
        const country = row.country
        return { pid, country, readable: false }
      },
      cell: (row) => {
        const { country } = row.getValue()
        return country
      },
    },
    ...Products.map((product) => ({
      id: `pid-${product.code}`,
      header: () => {
        return (
          <div onClick={() => onHeadClick(product.code)}>
            {product.code}-{product.name}
          </div>
        )
      },
      accessorFn: (row) => {
        const pid = product.code
        const country = row.country
        const readable = row[product.code]
        return { readable, pid, country }
      },
      cell: (row) => {
        const { readable, pid, country } = row.getValue()
        return <ToggleCell readable={readable} pid={pid} country={country} />
      },
    })),
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const onCellClick = (cell) => {
    const { pid, country } = cell.getValue()
    onUpdateReadable(`${pid}-${country}`)
  }
  const onHeadClick = (pid) => {
    onUpdateReadable(`${pid}-*`)
  }

  return (
    <div className="flex flex-col gap-4">
      <ul className="my-2 ml-6 list-disc [&>li]:mt-2">
        <li>{JSON.stringify(reads, null, null)}</li>
        <li>* = 所有国家 or 所有产品</li>
        <li>Total = 国家或产品的总数，包含已经排除掉的国家或者产品。</li>
      </ul>
      <Button variant="outline" onClick={() => onUpdateReadable('*-*')}>
        All product and all country
      </Button>
      <MyTable table={table} onCellClick={onCellClick} />
    </div>
  )
}

const ToggleCell = ({ readable, pid, country }) => {
  return (
    <div className={cn(readable ? 'bg-primary' : 'bg-destructive', 'h-6 w-6')}>
      {readable ? (
        <Check className="text-primary-foreground" />
      ) : (
        <X className="text-destructive-foreground" />
      )}
    </div>
  )
}

export default SetComponent
