import React, { useMemo } from 'react'
import { Drawer, DrawerContent } from '@/components/ui/drawer'
import { create } from 'zustand'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { TrashIcon } from '@radix-ui/react-icons'
import { delFetcher, postFetcher } from 'hooks'
import { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'
import { useDatePicker } from 'components/MyDatePicker'
import { usePid2 } from 'components/DropdownPid'
import { useCountry } from 'components/DropdownCountry'
import { usePackageName } from './Home/DropdownPackage'

import dayjs from 'dayjs'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Textarea } from '@/components/ui/textarea'

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { toast } from '@/components/ui/use-toast'
import { DialogClose } from '@radix-ui/react-dialog'

import useSWR from 'swr'

import localizedFormat from 'dayjs/plugin/localizedFormat'
import MyTable from 'components/MyTable'
dayjs.extend(localizedFormat)

export const useFetchNotes = () => {
  const pids = usePid2((state) => state.pids)
  const pid = pids.join(',') === '' ? 'total' : pids.join(',')
  const country = useCountry((state) => state.value)
  const packageName = usePackageName((state) => state.value)
  const { startDate, endDate } = useDatePicker()
  const { data: notes } = useSWR(['/notes', { startDate, endDate }])
  const filtedNotes = useMemo(() => {
    return notes
      ?.filter((note) => pid === 'total' || note.pid === pid)
      ?.filter((note) => country === 'total' || note.country === country)
      ?.filter(
        (note) =>
          packageName === '' ||
          packageName === 'total' ||
          note.packageName === packageName,
      )
  }, [notes, pid, country, packageName])
  return filtedNotes
}

export const useNoteStore = create((set) => ({
  date: '',
  open: false,
  showNotes: (payload) => {
    const { date } = payload
    set({
      date: date,
      open: true,
    })
  },
  onOpenChange: (value) => {
    if (value) {
      set({ open: true })
    } else {
      set({ open: false, date: '' })
    }
  },
}))

const NotesDrawer = () => {
  const { open, onOpenChange, date } = useNoteStore()
  const { startDate, endDate } = useDatePicker()

  const { trigger } = useSWRMutation('/notes/', delFetcher, {
    onSuccess: () => {
      mutate([`/notes`, { startDate, endDate }])
    },
  })
  const notes = useFetchNotes()
  const filterNotes = useMemo(
    () => notes?.filter((n) => n.date === date) ?? [],
    [notes, date],
  )

  const columns = [
    // { id: 'index', header: 'Index', cell: ({ row }) => row.index + 1 },
    {
      accessorKey: 'pid',
      header: 'Pid',
      cell: ({ row }) => row.getValue('pid'),
    },
    {
      accessorKey: 'country',
      header: 'Country',
      cell: ({ row }) => row.getValue('country'),
    },
    {
      accessorKey: 'packageName',
      header: 'Package name',
      cell: ({ row }) => row.getValue('packageName'),
    },
    {
      accessorKey: 'cost',
      header: 'Note',
      cell: ({ row }) => row.getValue('cost'),
    },
    {
      accessorKey: 'updateTime',
      header: 'Logtime',
      cell: ({ row }) => {
        const updateTime = row.getValue('updateTime')
        const formatted = dayjs(updateTime).format('YYYY-MM-DD LT')
        return formatted
      },
    },
    {
      id: 'opt',
      accessorFn: (row) => row.id,
      header: 'Opt',
      cell: (row) => {
        const id = row.getValue()
        return (
          <TrashIcon
            className="h-4 w-4 text-destructive hover:outline"
            onClick={() => trigger(id)}
          />
        )
      },
    },
  ]

  const table = useReactTable({
    data: filterNotes,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent>
        <div className="mx-auto w-full max-w-auto p-4">
          <div className="flex items-center py-4">
            <CreateNote />
          </div>
          <div className="overflow-y-auto max-h-80">
            <MyTable table={table} />
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  )
}

const FormSchema = z.object({
  cost: z.string().min(2, {
    message: 'note must be at least 2 characters.',
  }),
})
export function CreateNote() {
  const pids = usePid2((state) => state.pids)
  const pid = pids.join(',') === '' ? 'total' : pids.join(',')
  const country = useCountry((state) => state.value)
  const packageName = usePackageName((state) => state.value)
  const date = useNoteStore((state) => state.date)
  const { startDate, endDate } = useDatePicker()
  const { trigger } = useSWRMutation(`/notes`, postFetcher)

  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      cost: '',
    },
  })

  function onSubmit(data) {
    toast({
      title: 'You submitted the following values:',
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(data, null, 2)}</code>
        </pre>
      ),
    })

    if (!data.cost) {
      return
    }

    const body = { cost: data.cost, pid, country, date, packageName }
    trigger(body, {
      onSuccess: () => {
        mutate([`/notes`, { endDate, startDate }])
      },
    })
  }

  if (pids?.length > 1) {
    return null
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">Create note</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create note</DialogTitle>
          <div className="flex flex-col gap-2">
            <span>
              Date:<span className="text-destructive">{date}</span>
            </span>
            <span>
              Pid:<span className="text-destructive">{pid}</span>
            </span>
            <span>
              Country:<span className="text-destructive">{country}</span>
            </span>
            <span>
              Package name:
              <span className="text-destructive">{packageName}</span>
            </span>
          </div>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <FormField
                control={form.control}
                name="cost"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Textarea placeholder="" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <DialogClose asChild>
                <Button type="submit">Submit</Button>
              </DialogClose>
            </form>
          </Form>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default NotesDrawer
